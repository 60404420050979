import React, { useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../components/Layouts/Layout';
import SEO from '../components/seo';

export default function Upasana() {
  const [openTab, setOpenTab] = useState(1);

  return (
    <Layout>
      <SEO title='Upasana' />
      <section className='relative bg-gray-200'>
        <div className='container mx-auto px-4 pb-32 pt-24'>
          <ul
            className='flex mb-0 list-none flex-wrap pt-3 flex-row'
            role='tablist'
          >
            <li className='-mb-px mr-2 last:mr-0 mb-2 flex-auto text-center'>
              <a
                className={`text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal ${
                  openTab === 1
                    ? 'text-white bg-orange-500'
                    : 'text-orange-500 bg-white'
                }`}
                onClick={e => {
                  e.preventDefault();
                  setOpenTab(1);
                }}
                data-toggle='tab'
                href='#link1'
                role='tablist'
              >
                Bhagwan Swaminarayan
              </a>
            </li>
            <li className='-mb-px mr-2 last:mr-0 mb-2 flex-auto text-center'>
              <a
                className={`text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal ${
                  openTab === 2
                    ? 'text-white bg-orange-500'
                    : 'text-orange-500 bg-white'
                }`}
                onClick={e => {
                  e.preventDefault();
                  setOpenTab(2);
                }}
                data-toggle='tab'
                href='#link2'
                role='tablist'
              >
                Gunatitanand Swami
              </a>
            </li>
            <li className='-mb-px mr-2 last:mr-0 mb-2 flex-auto text-center'>
              <a
                className={`text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal ${
                  openTab === 3
                    ? 'text-white bg-orange-500'
                    : 'text-orange-500 bg-white'
                }`}
                onClick={e => {
                  e.preventDefault();
                  setOpenTab(3);
                }}
                data-toggle='tab'
                href='#link3'
                role='tablist'
              >
                Shastriji Maharaj
              </a>
            </li>
            <li className='-mb-px mr-2 last:mr-0 mb-2 flex-auto text-center'>
              <a
                className={`text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal ${
                  openTab === 4
                    ? 'text-white bg-orange-500'
                    : 'text-orange-500 bg-white'
                }`}
                onClick={e => {
                  e.preventDefault();
                  setOpenTab(4);
                }}
                data-toggle='tab'
                href='#link4'
                role='tablist'
              >
                Yogi Maharaj
              </a>
            </li>
            <li className='-mb-px mr-2 last:mr-0 mb-2 flex-auto text-center'>
              <a
                className={`text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal ${
                  openTab === 5
                    ? 'text-white bg-orange-500'
                    : 'text-orange-500 bg-white'
                }`}
                onClick={e => {
                  e.preventDefault();
                  setOpenTab(5);
                }}
                data-toggle='tab'
                href='#link5'
                role='tablist'
              >
                Hariprasad Swami Maharaj
              </a>
            </li>
          </ul>
          <div className='relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded'>
            <div className='px-4 flex-auto'>
              <div className='tab-content tab-space'>
                <div className={openTab === 1 ? 'block' : 'hidden'} id='link1'>
                  <div className='container mx-auto px-4 pb-32 pt-24'>
                    <div className='items-center flex flex-wrap'>
                      <div className='w-full md:w-6/12 mr-auto px-4 pt-0'>
                        <StaticImage
                          src='../images/upasana/swaminarayan-bhaghwan.jpg'
                          width={500}
                          quality={100}
                          formats={['AUTO', 'WEBP', 'AVIF']}
                          className='max-w-full rounded-lg'
                          alt='Haridham India'
                        />
                      </div>
                      <div className='w-full md:w-6/12 ml-auto px-4'>
                        <div className='md:pr-12'>
                          <h3 className='text-3xl font-semibold text-orange-500'>
                            BHAGWAN SWAMINARAYAN
                          </h3>
                          <ul className='list-none mt-6'>
                            <li className='py-2'>
                              <div className='flex items-center'>
                                <h4 className='text-gray-600'>
                                  <strong>Name:</strong> Ghanshyam Maharaj
                                </h4>
                              </div>
                            </li>
                            <li className='py-2'>
                              <div className='flex items-center'>
                                <h4 className='text-gray-600'>
                                  <strong>Life Span:</strong> 1781 A.D – 1830
                                  A.D.
                                </h4>
                              </div>
                            </li>
                            <li className='py-2'>
                              <div className='flex items-center'>
                                <h4 className='text-gray-600'>
                                  <strong>Name After Initiation:</strong> Swami
                                  Sahajanand
                                </h4>
                              </div>
                            </li>
                            <li className='py-2'>
                              <div className='flex items-center'>
                                <h4 className='text-gray-600'>
                                  <strong>Place of Birth:</strong> Chhappaiya
                                  (Dist. Ayodhya, Uttar Pradesh, India) A.D.
                                </h4>
                              </div>
                            </li>
                            <li className='py-2'>
                              <div className='flex items-center'>
                                <h4 className='text-gray-600'>
                                  <strong>Life Work:</strong>
                                  <ul
                                    style={{
                                      listStyleType: 'circle',
                                      marginLeft: '20px',
                                    }}
                                  >
                                    <li>Initiated Swaminarayan Sect</li>
                                    <li>
                                      Propagated Vishishtadwaita at the age of
                                      seven
                                    </li>
                                    <li>
                                      Initiated 500 Paramhansas at the age of 21
                                    </li>
                                    <li>
                                      Put an end to violent oblation, traditions
                                      of sati , child marriage, infanticide
                                    </li>
                                    <li>
                                      Gave first prose in binded form in
                                      Gujarati language
                                    </li>
                                    <li>Special worshiping areas for women</li>
                                    <li>
                                      Promulgation of Nishkaam, Dharma and
                                      Nirman Dharma
                                    </li>
                                    <li>
                                      Absolute and total celibacy for saints
                                      after initiation
                                    </li>
                                  </ul>
                                </h4>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className='w-full ml-auto px-4 mt-4 text-gray-600'>
                      <p className='mt-4'>
                        Lord Swaminarayan manifested Himself in the year 1781
                        A.D. 224 years ago in the small hamlet of Chhapaiya near
                        Ayodhya in Northern India. As the proverb goes, “The
                        qualities of son are known from his birth.” Similarly,
                        Ghanshyam showed a lot of dexterity in deeds and
                        divinity in action during His tender age. As
                        Bhagavatacharya Sri Markendey prophesied, “this kid is
                        the manifestation of God. He shall bring absolute
                        happiness and prosperity to one and all whoever will
                        come into His divine contact.” True to the words, He won
                        the scriptural debate among the pandits of India in
                        Kashi at the age of seven years.
                      </p>
                      <p className='mt-4'>
                        He renounced the worldly life at the age of eleven and
                        was known as Nilkanth Varni. During His seven and half
                        years of sojourn in the jungle, He walked the whole span
                        of greater India from greater Himalayas to the end of
                        land, Kanyakumari in South and Assam in East and Gujarat
                        in west. At the end of His entire journey which set the
                        path for the benefit for all and triumph over all evil,
                        He settled in the village of Loj in Saurashtra. He
                        accepted Ramanand Swami as a Guru for all humanity:
                        <ul
                          className='mt-2'
                          style={{
                            listStyleType: 'circle',
                            marginLeft: '20px',
                          }}
                        >
                          <li>
                            Let me suffer million times but do not let my
                            devotee suffer one bit.
                          </li>
                          <li>
                            My devotee shall never go hungry even if he is the
                            victim of seven famines.
                          </li>
                          <li>
                            I shall take my devotee to the abode of God –
                            Akshardham at the end of his mortal life.
                          </li>
                          <li>
                            I shall be present on this Earth eternally through
                            the God-Ordained Saint who has renounced women,
                            wealth and fame.
                          </li>
                        </ul>
                      </p>
                      <p className='mt-4'>
                        He initiated 500 Paramhansas (saints) in one night at
                        the age of 21. He was the mentor of millions of persons
                        who had never visualized the happiness that was to be
                        their domain. Most of them were uneducated warriors and
                        farmers. He conquered sword wielding warriors not by
                        wielding sword but immersing them in His divine love.
                      </p>
                      <p className='mt-4'>
                        He resurrected them from the brink of barbarism and
                        elevated them to the level of God fearing devotees. He
                        was probably the most advanced reformer who worked at
                        the lowest strata of the society enriching them to be
                        the part of the happy, serene and truthful society. He
                        put an end to practice of Sati, infanticide and many
                        other vices that plagued the society of yore. Joban
                        Pagi, Bhaguji, Alaiya Khachar etc. are the examples of
                        His unflinching effort to revive humanity to it’s’ best.
                        He wrote ‘Shikshapatri’, 212 verses of perennial
                        importance that if followed, would bring ethereal peace
                        and prosperity; in the material world and metaphysical
                        world. He also dictated discourses known as
                        ‘Vachanamritam’, the first compiled prose in the history
                        of Gujarati Language.
                      </p>
                      <p className='mt-4'>
                        He left his mortal body at the age of 49 yrs., living an
                        indelible impression on the future. Millions of lives
                        were transformed from human to superlative being through
                        His divine contact.
                      </p>
                      <p className='mt-4'>
                        He always said, “The objective of My manifestation in
                        this world is to cultivate Service and Devotion.”
                        Service unto Humanity and Devotion unto God are the two
                        goals which every follower of Lord Swaminarayan
                        followed. In a nutshell, He was the propagator of
                        Nishkam-Dharma, the spirituality of desirelessness.
                      </p>
                    </div>
                  </div>
                </div>

                <div className={openTab === 2 ? 'block' : 'hidden'} id='link2'>
                  <div className='container mx-auto px-4 pb-32 pt-24'>
                    <div className='items-center flex flex-wrap'>
                      <div className='w-full md:w-6/12 mr-auto px-4 pt-0'>
                        <StaticImage
                          src='../images/upasana/gunatit-swami.jpg'
                          width={500}
                          quality={100}
                          formats={['AUTO', 'WEBP', 'AVIF']}
                          className='max-w-full rounded-lg'
                          alt='Haridham India'
                        />
                      </div>
                      <div className='w-full md:w-6/12 ml-auto px-4'>
                        <div className='md:pr-12'>
                          <h3 className='text-3xl font-semibold text-orange-500'>
                            GUNATITANAND SWAMI
                          </h3>
                          <ul className='list-none mt-6'>
                            <li className='py-2'>
                              <div className='flex items-center'>
                                <h4 className='text-gray-600'>
                                  <strong>Name:</strong> Mulji Sharma
                                </h4>
                              </div>
                            </li>
                            <li className='py-2'>
                              <div className='flex items-center'>
                                <h4 className='text-gray-600'>
                                  <strong>Life Span:</strong> 1785 A.D. – 1867
                                  A.D.
                                </h4>
                              </div>
                            </li>
                            <li className='py-2'>
                              <div className='flex items-center'>
                                <h4 className='text-gray-600'>
                                  <strong>Name After Initiation:</strong> Sadhu
                                  Nirgunanand, Gunatitand Swami
                                </h4>
                              </div>
                            </li>
                            <li className='py-2'>
                              <div className='flex items-center'>
                                <h4 className='text-gray-600'>
                                  <strong>Place of Birth:</strong> Bhadara
                                  (Dist. Jamnagar, Gujarat, India)
                                </h4>
                              </div>
                            </li>
                            <li className='py-2'>
                              <div className='flex items-center'>
                                <h4 className='text-gray-600'>
                                  <strong>Life Work:</strong>
                                  <ul
                                    style={{
                                      listStyleType: 'circle',
                                      marginLeft: '20px',
                                    }}
                                  >
                                    <li>Built the temple in Junagadh</li>
                                    <li>
                                      Defined the theory of Sakaar Brahman
                                    </li>
                                    <li>
                                      Imbibed the theories of devotion and
                                      servitude as preferred by Sahajanand Swami
                                    </li>
                                    <li>Incarnation of Brahm</li>
                                  </ul>
                                </h4>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className='w-full ml-auto px-4 mt-4 text-gray-600'>
                      <p className='mt-4'>
                        According to Lord Swaminarayan’s boon,I shall grace this
                        planet perpetually through the God Ordained Saint who is
                        absolutely pious and has renounced the company of women,
                        wealth and fame.
                      </p>
                      <p className='mt-4'>
                        Lord Swaminarayan blessed us with the legacy of saints
                        who were and are the beacon of true faith and empathy in
                        this fractured world.
                      </p>
                      <p className='mt-4'>
                        The first saint in this legacy of welfare promulgated by
                        Lord Swaminarayan was Mool Akshar Moorti Gunatitanand
                        Swami. He was the incarnation of Brahm where Lord
                        Swaminarayan was the embodiment of Parbhram. He was the
                        absolute sevak (aide) of the Lord. It was said that He
                        moved when the Lord’s eyes moved. He was the lexicon of
                        Lord’s wish. Physically, he was born in Bhadara, a tiny
                        village on the banks of river Und on the auspicious day
                        of Sharad-Poornima (autumn solstice Full moon) in the
                        year 1785 A.D.
                      </p>
                      <p className='mt-4'>
                        He was initiated as a saint and was named Nirgunanand –
                        the man unbound by the three physical bodies.
                      </p>
                      <p className='mt-4'>
                        He was also named as Gunatitanand Swami – man beyond the
                        boundaries of material world and virtues. Essentially,
                        Nirgunanand and Gunatitanand signify the same meaning.
                        He was the embodiment of service and devotion, the
                        crucial points to gratify Lord Swaminarayan. He was not
                        a mythical figure. He was the model for all the devotees
                        who aspired to be the supreme sevak of the Lord. He
                        strode this Earth propagating the divine message of Lord
                        Swaminarayan. He was the first natural successor of Lord
                        Swaminarayan.
                      </p>
                    </div>
                  </div>
                </div>
                <div className={openTab === 3 ? 'block' : 'hidden'} id='link3'>
                  <div className='container mx-auto px-4 pb-32 pt-24'>
                    <div className='items-center flex flex-wrap'>
                      <div className='w-full md:w-6/12 mr-auto px-4 pt-0'>
                        <StaticImage
                          src='../images/upasana/shastriji-maharaj.jpg'
                          width={500}
                          quality={100}
                          formats={['AUTO', 'WEBP', 'AVIF']}
                          className='max-w-full rounded-lg'
                          alt='Haridham India'
                        />
                      </div>
                      <div className='w-full md:w-6/12 ml-auto px-4'>
                        <div className='md:pr-12'>
                          <h3 className='text-3xl font-semibold text-orange-500'>
                            SHASTRIJI MAHARAJ
                          </h3>
                          <ul className='list-none mt-6'>
                            <li className='py-2'>
                              <div className='flex items-center'>
                                <h4 className='text-gray-600'>
                                  <strong>Name:</strong> Dungar Bhagat
                                </h4>
                              </div>
                            </li>
                            <li className='py-2'>
                              <div className='flex items-center'>
                                <h4 className='text-gray-600'>
                                  <strong>Life Span:</strong> 1865 A.D. – 1951
                                  A.D.
                                </h4>
                              </div>
                            </li>
                            <li className='py-2'>
                              <div className='flex items-center'>
                                <h4 className='text-gray-600'>
                                  <strong>Name After Initiation:</strong> Sadhu
                                  Yagnapurushdasji
                                </h4>
                              </div>
                            </li>
                            <li className='py-2'>
                              <div className='flex items-center'>
                                <h4 className='text-gray-600'>
                                  <strong>Place of Birth:</strong> Mahelav
                                  (Dist. Kheda, Gujarat, India)
                                </h4>
                              </div>
                            </li>
                            <li className='py-2'>
                              <div className='flex items-center'>
                                <h4 className='text-gray-600'>
                                  <strong>Life Work:</strong>
                                  <ul
                                    style={{
                                      listStyleType: 'circle',
                                      marginLeft: '20px',
                                    }}
                                  >
                                    <li>
                                      Propagated dual form of worship,Shri
                                      Akshar-Purushottam
                                    </li>
                                    <li>Built five pinnacle temples</li>
                                    <li>
                                      Idol installation of Shri
                                      Akshar-Purushottam Maharaj in each of the
                                      temples
                                    </li>
                                  </ul>
                                </h4>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className='w-full ml-auto px-4 mt-4 text-gray-600'>
                      <p className='mt-4'>
                        He initiated the dual worship of Sri Akshar Purushottam,
                        the essence of Swaminarayan sect; the philosophy which
                        had gone unnoticed among the followers! Akshar
                        Purushottam dual worship is a faith of devotion to God
                        with His most trusted and preferred devotee, Akshar.
                      </p>
                      <p className='mt-4'>
                        This dual worship helps us to understand the intricacies
                        of spirituality of pleasing Parbrahm with the help of
                        Akshar. A pupil needs teacher to accomplish his academic
                        goals. Similarly, any disciple needs Akshar as his guide
                        to realize his spiritual goal.
                      </p>
                      <p className='mt-4'>
                        Born in Mahelav, a small town in Gujarat, India, His
                        personality was effectual. His prowess was evident from
                        His illustrious childhood. His deeds evidenced the
                        devotion and spirituality that was unseen so far. With
                        his servility, He pleased one and all. Anadi Mahamukta
                        Bhagatji Maharaj was very pleased with Him. That made
                        Him the recipient of the intrinsic knowledge of Lord
                        Swaminarayan and M.A.M. Gunatitanand Swami. He studied
                        hard to compliment the knowledge He had received from
                        Anadi Mahamukta Bhagatji Maharaj.
                      </p>
                      <p className='mt-4'>
                        He was initiated as a Sadhu and named Yagnapurushdas.
                        His dexterity was second to none in saintliness and
                        learning. He became Shashtri in Sanskrit Scriptures; the
                        degree that earned Him the nickname, Shashtriji Maharaj.
                      </p>
                      <p className='mt-4'>
                        He experienced contemptuous behaviour from the society.
                        Undeterred in His persuasion of establishing the worship
                        of dual nature as propounded by Lord Swaminarayan, He
                        built five temples through which he spread this message
                        of devotion to Sri Akshar Purushottam Maharaj.
                      </p>
                      <p className='mt-4'>
                        These five temples are the pinnacles of accomplishments
                        in the history of Sri Akshar Purushottam worship. At the
                        end of His mortal life, He indicated Guruhari Yogiji
                        Maharaj as His successor, the manifestation of Sakar
                        Brahm.
                      </p>
                    </div>
                  </div>
                </div>
                <div className={openTab === 4 ? 'block' : 'hidden'} id='link4'>
                  <div className='container mx-auto px-4 pb-32 pt-24'>
                    <div className='items-center flex flex-wrap'>
                      <div className='w-full md:w-6/12 mr-auto px-4 pt-0'>
                        <StaticImage
                          src='../images/upasana/yogiji-maharaj.jpg'
                          width={500}
                          quality={100}
                          formats={['AUTO', 'WEBP', 'AVIF']}
                          className='max-w-full rounded-lg'
                          alt='Haridham India'
                        />
                      </div>
                      <div className='w-full md:w-6/12 ml-auto px-4'>
                        <div className='md:pr-12'>
                          <h3 className='text-3xl font-semibold text-orange-500'>
                            YOGI MAHARAJ
                          </h3>
                          <ul className='list-none mt-6'>
                            <li className='py-2'>
                              <div className='flex items-center'>
                                <h4 className='text-gray-600'>
                                  <strong>Name:</strong> Jinabhai
                                </h4>
                              </div>
                            </li>
                            <li className='py-2'>
                              <div className='flex items-center'>
                                <h4 className='text-gray-600'>
                                  <strong>Life Span:</strong> 1892 A.D. – 1971
                                  A.D.
                                </h4>
                              </div>
                            </li>
                            <li className='py-2'>
                              <div className='flex items-center'>
                                <h4 className='text-gray-600'>
                                  <strong>Name After Initiation:</strong> Sadhu
                                  Gnanjivandasji
                                </h4>
                              </div>
                            </li>
                            <li className='py-2'>
                              <div className='flex items-center'>
                                <h4 className='text-gray-600'>
                                  <strong>Place of Birth:</strong> Dhari (Dist.
                                  Amreli, Saurashtra, India)
                                </h4>
                              </div>
                            </li>
                            <li className='py-2'>
                              <div className='flex items-center'>
                                <h4 className='text-gray-600'>
                                  <strong>Life Work:</strong>
                                  <ul
                                    style={{
                                      listStyleType: 'circle',
                                      marginLeft: '20px',
                                    }}
                                  >
                                    <li>
                                      Nurtured serviced and devotion. (Nishkam
                                      Dharma and Nirman Dharma)
                                    </li>
                                    <li>
                                      Initiated 51 educated youths as sadhus
                                    </li>
                                    <li>
                                      Propagated Swaminarayan Sect outside
                                      India.
                                    </li>
                                  </ul>
                                </h4>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className='w-full ml-auto px-4 mt-4 text-gray-600'>
                      <p className='mt-4'>
                        Guruhari Yogiji Maharaj was born in Dhari in Saurastra.
                        His name was Jinabhai and true to His name, He remained
                        Jino (a little one) throughout His mortal life. He was
                        the forth successor of the divine legacy of saints.
                      </p>
                      <p className='mt-4'>
                        At the very small age, when a child is not even aware of
                        any rationalism, He used to serve the local temple in
                        Dhari waking up at 4.00AM in the morning. He started
                        performing the temple routine at the age of seven. He
                        would bathe the idols of Lord, apply sandalwood paste
                        and perform Aarti like an experienced pujari. His
                        devotion and servitude was not only exemplary, it was
                        supremely saintly. He was the byword of sevakbhav (the
                        model disciple of His spiritual master) and true
                        unreserved compassion.
                      </p>
                      <p className='mt-4'>
                        He was initiated as sadhu and named Sadhu
                        Gnanjivandasji. He was the ultimate model of devotion
                        and humility. Inspite being the head of the Akshar
                        mandir, Gondal; He was found in Kitchen in the wee hours
                        of morning, noon or night. Tolerance was the synonym and
                        devotion was intrinsic. For Sadhu Gnanjivandasji,
                        servitude was not the quality to be acquired but the
                        manifestation. Insults were His eulogy and treated one
                        as the offering from the Lord.
                      </p>
                      <p className='mt-4'>
                        Guruhari Shashtriji Maharaj defining His unison with
                        Yogi exclaimed, Huun te Jogi Ane Jogi te Hun. (I am Yogi
                        and Yogi is Me) He populated the idea of “Samp,
                        Suhradbhav and Ekta” – the practical ideology of unity
                        and universal brotherhood.
                      </p>
                      <p className='mt-4'>
                        He was an ever-smiling personality, the true replica of
                        a mother without the malice of the substance world. He
                        was the one who promulgated and promoted humility as an
                        asset and modesty as virtue, the most important for an
                        aspiring devotee. He was, one person who put the beliefs
                        in devotion and service in the devotee through His
                        idyllic behavior.
                      </p>
                    </div>
                  </div>
                </div>
                <div className={openTab === 5 ? 'block' : 'hidden'} id='link5'>
                  <div className='container mx-auto px-4 pb-32 pt-24'>
                    <div className='items-center flex flex-wrap'>
                      <div className='w-full md:w-6/12 mr-auto px-4 pt-0'>
                        <StaticImage
                          src='../images/upasana/hariprasad-maharaj.jpg'
                          width={500}
                          quality={100}
                          formats={['AUTO', 'WEBP', 'AVIF']}
                          className='max-w-full rounded-lg'
                          alt='Haridham India'
                        />
                      </div>
                      <div className='w-full md:w-6/12 ml-auto px-4'>
                        <div className='md:pr-12'>
                          <h3 className='text-3xl font-semibold text-orange-500'>
                            H.D.H. HARIPRASAD SWAMI MAHARAJ
                          </h3>
                          <ul className='list-none mt-6'>
                            <li className='py-2'>
                              <div className='flex items-center'>
                                <h4 className='text-gray-600'>
                                  <strong>Name:</strong> Prabhudasbhai
                                </h4>
                              </div>
                            </li>
                            <li className='py-2'>
                              <div className='flex items-center'>
                                <h4 className='text-gray-600'>
                                  <strong>Life Span:</strong> 1934 - Present
                                </h4>
                              </div>
                            </li>
                            <li className='py-2'>
                              <div className='flex items-center'>
                                <h4 className='text-gray-600'>
                                  <strong>Name After Initiation:</strong> Sadhu
                                  Hariprasad
                                </h4>
                              </div>
                            </li>
                            <li className='py-2'>
                              <div className='flex items-center'>
                                <h4 className='text-gray-600'>
                                  <strong>Place of Birth:</strong> Asoj (Dist.
                                  Baroda, Gujarat, India)
                                </h4>
                              </div>
                            </li>
                            <li className='py-2'>
                              <div className='flex items-center'>
                                <h4 className='text-gray-600'>
                                  <strong>Life Work:</strong>
                                  <ul
                                    style={{
                                      listStyleType: 'circle',
                                      marginLeft: '20px',
                                    }}
                                  >
                                    <li>Founded Yogi Divine Society.</li>
                                    <li>
                                      He toiled day and night to foster the
                                      principle of Atmiyata (Spiritual Affinity
                                      with one and all)
                                    </li>
                                    <li>
                                      Today, because of His divine prowess and
                                      sublime selfless love, there are thousands
                                      of youths whose lives are the model of
                                      true piety, devoid of vices.
                                    </li>
                                  </ul>
                                </h4>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className='w-full ml-auto px-4 mt-4 text-gray-600'>
                      <p className='mt-4'>
                        Prabhudasbhai (Swamiji’s name before sainthood) was born
                        in Asoj, a small village near Baroda in Gujarat on May
                        4th 1934. He was brilliant in His studies and modest in
                        acclaiming His achievement since His childhood.
                        H.D.Swami was initiated by Guruhari Yogiji Maharaj.
                        While proffering diksha on His chosen devotee, Guruhari
                        Yogiji Maharaj proclaimed, “This diksha is like the
                        diksha proffered upon M.A.M. Gunatitanand Swami by Lord
                        Swaminarayan in Dabhan (the place where M.A.M.
                        Gunatitanand Swami was initiated into sainthood).” These
                        words coming out of Yogiji’s divine mouth were not mere
                        eulogy. These words were futuristic and were spoken by a
                        visionary. It showed the path which the prospect had to
                        take in the future. True to this prophecy, Swamiji
                        became the fifth spiritual successor of the legacy of
                        Lord Swaminarayan. He resolutely followed the regimen of
                        Guruhari Yogiji Maharaj’s volition. To streamline the
                        resolution, He founded Yogi Divine Society.
                      </p>
                      <p className='mt-4'>
                        He toiled day and night to foster the principle of
                        Atmiyata (Spiritual Affinity with one and all) which is
                        just the other way to foster the principle of samp,
                        suhradbhav and ekta. Swamiji has loved and all. He is
                        known as the most secular saint in the contemporary
                        world. Swamiji identifies all with Yogiji Maharaj, and
                        that is the reason he is acclaimed as a universal saint
                        who strives to cultivate humanity, devotion and harmony
                        in an individual, in the society, in the nation and in
                        the world. His dictum is, “O Lord! Whether the other
                        person becomes Atmiya (spiritually harmonious) or not,
                        please make me Atmiya, (spiritually harmonious)!”
                        meaning, a devotee irrespective of the surroundings,
                        circumstances, nature, temperament has to be harmonious
                        and devoted to His Lord and the spiritual master. Today,
                        because of His divine prowess and sublime selfless love,
                        there are thousands of youths whose lives are the model
                        of true piety, devoid of vices.
                      </p>
                      <p className='mt-4'>
                        His magnanimity has led the communion to live the life
                        in accordance to the set principles of Lord Swaminarayan
                        and the saints without artificial persuasion.
                      </p>
                      <p className='mt-4'>
                        His life and work could be more justified when we
                        navigate through the activities inspired by Him, carried
                        out under the auspices of Yogi Divine Society.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}
